import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Menu } from "@material-ui/core";
import { Select } from "@material-ui/core";
import { InputLabel } from "@material-ui/core";
import { Paper } from "@material-ui/core"
import { MenuItem } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import styles from "../assets/jss/glifos/registryScreenStyle.js";
import FileUplaoder from '../components/FileUploader.js';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FileDetailTable from '../components/FileDetailTable.js';
import { useDispatch, useSelector } from 'react-redux';
import ApiURLs from '../config/ApiURLs.js';
import LoadingIndicator from '../components/LoadingIndicator.js';
import axios from 'axios';
import fileDownload from 'js-file-download';
import { signout } from '../actions/userActions.js';
const useStyles = makeStyles(styles);


export default function RegistryScreenCopy(props) {
    const classes = useStyles();
    const files = useSelector(state => state.document.archivos);
    const [currentStep, setCurrentStep] = React.useState(0);//0 Data lot xslx upload
    const [dataRep, setDataRep] = React.useState({ isLoading: true, data: [] });
    const [year, setYear] = React.useState(2021);
    const [institution, setInstitution] = React.useState("");
    const [trimestre, setTrimestre] = React.useState(2);
    const userInfo = JSON.parse(localStorage.getItem('userInfo'));
    const userInstitution = userInfo.institutions[0].name + " (" + userInfo.institutions[0].code + ")";
    const dispatch = useDispatch();
    const [dataTables, setDataTables] = React.useState([]);
    const [dataTable, setDataTable] = React.useState(0);

    //1 Lot detail & confirmation 

    const descriptionElementRef = React.useRef(null); //Modal related

    const handleFormStepChange = (forward) => {
        if (!forward && currentStep == 0) //Nunca deberia pasar porque en el render se pregunta si se debe o no renderear, pero por si acaso
            return;
        if (forward) {
            setCurrentStep(currentStep + 1);
        }
        else
            setCurrentStep(currentStep - 1);
    }

    const handleClose = () => {
        if (currentStep == 2)
            setCurrentStep(1);//Si el usuario no aceptó el dialogo principal entonces lo regresamos al paso 2
        else {
            //redirect a history
        }
    }

    const openInNewTab = (url) => {
        axios.get(url, {
            responseType: 'blob',
            headers: {
                Authorization: 'Bearer ' + userInfo.access_token //the token is a variable which holds the token
            }
        }).then(res => {
            fileDownload(res.data, "PLANTILLA_" + institution + ".xlsx");
        }).catch(err => {
            /* if (err.response.status === 401) {
                dispatch(signout());
            } */
        });
    }

    React.useEffect(() => {
        if (currentStep == 2) { ///Modal related
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
        if (currentStep == 1)//entonces hacemos la peticion de la metadata del archivo recien subido
        {
            //acá realmente deberia utilizar el api service, pero no me queda claro como pasarle un queryParam
            //axios.get(ApiURLs.baseURL+'lot/lot-data?id='+files[0].id+'&institution='+institution).then((data)=>{
            axios.get(ApiURLs.baseURL + 'lot-data-table/data?id=' + files[0].id + '&institution=' + institution + '&dataTable=' + dataTable, {
                headers: {
                    Authorization: 'Bearer ' + userInfo.access_token //the token is a variable which holds the token
                }
            }).then((data) => {
                console.log("Data", data);
                setDataRep({ isLoading: false, data: data.data.data });
            }).catch(err => {
                if (err.response.status === 401) {
                    dispatch(signout());
                }

            });
        }
        if (currentStep == 3) {
            // ha sido aceptada la data
            axios.get(ApiURLs.baseURL + 'lot-data-table/approve?id=' + files[0].id + '&institution=' + institution + '&dataTable=' + dataTable, {
                headers: {
                    Authorization: 'Bearer ' + userInfo.access_token //the token is a variable which holds the token
                }
            }).then((data) => {
                console.log("Data", data);
                setDataRep({ isLoading: false, data: data.data.data });
            }).catch(err => {
                if (err.response.status === 401) {
                    dispatch(signout());
                }

            });
        }
    }, [currentStep]);

    const getDataTables = (institution) => {
        setDataTables((prevVal) => ({ ...prevVal, isLoading: true }));

        axios.get(`${ApiURLs.baseURL}data-table-template/get-data-table?institution=${institution}`, {
            headers: {
                Authorization: 'Bearer ' + userInfo.access_token //the token is a variable which holds the token
            }
        }).then((data) => {
            console.log("Data", data);
            setDataTables({ isLoading: false, data: data.data });
        }).catch(err => {
            if (err.response.status === 401) {
                dispatch(signout());
            } else if (err.response.status === 404) {
                alert('Esta institución no tiene ninguna tabla de datos creada')
            } else {
                alert('Hubo un error inesperado')
            }
        }).finally(() => {
            setDataTables((prevVal) => ({ ...prevVal, isLoading: false }));
        });
    };

    const header = () => {
        return (
            <Paper className={classes.paper}>
                <Typography variant='h4'>Ingreso y Registro</Typography>
                <div className={classes.stepIndicatorContainer}>
                    <Typography variant="subtitle1">{currentStep + 1} de 3</Typography>
                </div>
            </Paper>

        )
    };

    const footer = () => {
        var requiresLeft = currentStep !== 0;
        if (currentStep > 1)
            return null;
        return (
            <div className={classes.formFooterButtons}>
                {requiresLeft && (
                    <div className={classes.formFooterLeftButton}>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            startIcon={<i class="fas fa-arrow-circle-left"></i>}
                            onClick={() => handleFormStepChange(false)}
                        >
                            Cancelar
                        </Button>
                    </div>
                )
                }
                <div className={classes.formFooterRightButton} style={{ flex: requiresLeft ? undefined : 1 }}>
                    <Button
                        disabled={currentStep == 0 && files[0] == undefined ? true : false}
                        variant="contained"
                        color="primary"
                        size="large"
                        startIcon={<i class="fas fa-arrow-circle-right"></i>}
                        onClick={() => handleFormStepChange(true)}
                    >
                        {
                            currentStep == 1 ? "Confirmar" : "Continuar"
                        }
                    </Button>
                </div>
            </div>
        );
    }

    const stepOne = () => {
        let validYears = [];
        let ThisYear = new Date().getFullYear();

        for (let i = ThisYear; i > (ThisYear - 10); i--)
            validYears[validYears.length] = i;
        return (
            <div>
                <div className={classes.subStep}>
                    <Typography variant="h4" style={{ marginBottom: '10px' }}>Paso 1</Typography>
                    <div className={classes.inputContainer}>
                        <div className={classes.inputLabel}>
                            <InputLabel >Seleccione la Institución</InputLabel>
                        </div>
                        {/*<TextField disabled variant={'outlined'} defaultValue={userInstitution} />*/}
                        <Select
                            value={institution}
                            variant={'outlined'}
                            inputProps={{ style: { padding: '0px !important' } }}
                            style={{ padding: '0px !important' }}
                            onChange={(event) => { setInstitution(event.target.value); getDataTables(event.target.value) }}
                        >
                            {
                                userInfo.institutions.map((element, index) => {
                                    return (
                                        <MenuItem value={element.code}>{element.name + " (" + element.code + ")"}</MenuItem>
                                    );
                                })
                            }
                        </Select>
                    </div>

                    {institution !== "" ?
                        <>
                            <div className={classes.inputContainer}>
                                <div className={classes.inputLabel}>
                                    <InputLabel>Seleccione el reader</InputLabel>
                                </div>
                                {/*<TextField disabled variant={'outlined'} defaultValue={userInstitution} />*/}
                                <Select
                                    variant={'outlined'}
                                    inputProps={{ style: { padding: '0px !important' } }}
                                    style={{ padding: '0px !important' }}
                                    onChange={(event) => { setDataTable(event.target.value); }}
                                >
                                    {
                                        !dataTables.isLoading ? dataTables.data?.map((element, index) => {
                                            return (
                                                <MenuItem value={element.id}>{element.name}</MenuItem>
                                            );
                                        }) : <></>
                                    }
                                </Select>
                            </div>
                        </> : <></>}

                    <div className={classes.columnarFlex}>
                        <Typography variant="subtitle1">Descarga la plantilla para poder llenar los datos</Typography>
                        <Button
                            variant="contained"
                            color="primary"
                            size="large"
                            //className={classes.button}
                            startIcon={<i class="fas fa-file-excel fa-3x"></i>}
                            onClick={() => { openInNewTab(`${ApiURLs.baseURL}data-table-template/template?id=${dataTable}&institution=${institution}`) }}
                        >
                            Descargar
                        </Button>
                    </div>
                </div>
                <div className={classes.subStep}>
                    <Typography variant="h4" style={{ marginBottom: '10px' }}>Paso 2</Typography>
                    <div className={classes.columnarFlex}>
                        <Typography variant="subtitle1">Suba el excel con los datos llenos</Typography>
                    </div>
                    {/* Aqui va el codigo refactorizado de circulation screen */}
                    <div className={classes.fileUploaderContainer}>
                        <FileUplaoder singleFile allowedFiles={[".xlsx", ".xls"]} />
                    </div>
                </div>
            </div>
        );
    };

    const stepTwo = () => {
        if (dataRep.isLoading)
            return <LoadingIndicator />
        return (
            <div className={classes.fileUploaderContainer}>
                <Typography
                    component='h3'
                    variant='h6'
                    color='primary'
                    gutterBottom>
                    Resumen - Datos del lote
                </Typography>
                <FileDetailTable rows={dataRep.data} />
            </div>
        )
    };

    const stepThree = () => {
        return (
            <>
                <div className={classes.fileUploaderContainer}>
                    <Typography
                        component='h3'
                        variant='h6'
                        color='primary'
                        gutterBottom>
                        Resumen - Datos del lote
                    </Typography>
                    <FileDetailTable rows={dataRep.data} />
                </div>
                <Dialog
                    open={currentStep == 2}
                    onClose={handleClose}
                    scroll={"paper"}
                    aria-labelledby="scroll-dialog-title"
                    aria-describedby="scroll-dialog-description"
                >
                    <DialogTitle id="scroll-dialog-title">{(userInfo.institutions.find(function (ins, index) { if (ins.code == institution) return true; })).name + " - " + institution}</DialogTitle>
                    <DialogContent dividers={true}>
                        <DialogContentText
                            id="scroll-dialog-description"
                            ref={descriptionElementRef}
                            tabIndex={-1}
                        >
                            {`Desea proceder?`}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancelar
                        </Button>
                        <Button onClick={() => handleFormStepChange(true)} color="primary">
                            Aceptar y continuar
                        </Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }

    const stepFour = () => { ///Notificaction of sucess dialog
        return (
            <Dialog onClose={() => handleFormStepChange(true)} aria-labelledby="customized-dialog-title" open={currentStep == 3}>
                <DialogTitle id="customized-dialog-title" onClose={() => handleFormStepChange(true)}>
                    {institution + " - Finalizado"}
                </DialogTitle>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Gracias, la informacion ha sido almacenada correctamente
                    </Typography>
                    <div className={classes.dialogCenteredContent}>
                        <i class="fas fa-check fa-3x"></i>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button autoFocus onClick={() => handleFormStepChange(true)} color="primary">
                        Cerrar
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }

    const renderCurrentStep = () => {
        if (currentStep == 0)
            return stepOne();
        else if (currentStep == 1)
            return stepTwo();
        else if (currentStep == 2)
            return stepThree();
        else if (currentStep == 3)
            return stepFour();
    }


    if (currentStep == 4)
        window.location.href = '/';
    //return <Redirect to="/"/>
    return (
        <div>

            {header()}
            {renderCurrentStep()}
            {footer()}
        </div>
    );

}