import React, { useEffect, useState } from 'react';
import { BrowserRouter, Route, Redirect, Switch } from 'react-router-dom';
import DashboardScreen from './screens/DashboardScreen';
import CatalogingScreen from './screens/CatalogingScreen';
import CirculationScreen from './screens/CirculationScreen';
import { } from 'react-router-dom';

import './App.css';
import LocationScreen from './screens/LocationScreen';
import DigitizationScreen from './screens/DigitizationScreen';
import ReceptionScreen from './screens/ReceptionScreen';
import MainLayout from './layouts/MainLayout';
import { useSelector } from 'react-redux';
import LoginScreen from './screens/LoginScreen';
import NotFoundScreen from './screens/NotFoundScreen';
import PrivateRoute from './validators/PrivateRoute';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import RegistryScreen from './screens/RegistryScreen';
import HistoryScreen from './screens/HistoryScreen';
import RegistryScreenCopy from './screens/RegistryScreenCopy';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#4D4D4D'
    }
  }
});

function App() {
  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;


  return (
    <MuiThemeProvider theme={theme}>
      <BrowserRouter>
        <MainLayout userInfo={userInfo}>
          <Switch>
            <Route path='/upload-data/login' component={LoginScreen} />
            <Route path='/login' component={LoginScreen} />

            <Route path='/upload-data' exact>
              <Redirect to="/upload-data/registry" />
            </Route>

            <Route path='/' exact>
              <Redirect to="/upload-data/registry" />
            </Route>
            {/* <PrivateRoute path='/dashboard' component={DashboardScreen} exact></PrivateRoute>
            <PrivateRoute path='/cataloging' component={CatalogingScreen} exact></PrivateRoute>
            <PrivateRoute path='/circulation' component={CirculationScreen} exact></PrivateRoute>
            <PrivateRoute path='/location' component={LocationScreen} exact></PrivateRoute>
            <PrivateRoute path='/digitization' component={DigitizationScreen} exact></PrivateRoute>
            <PrivateRoute path='/reception' component={ReceptionScreen} exact></PrivateRoute> */}
            <PrivateRoute path='/upload-data/registry' component={RegistryScreen} exact></PrivateRoute>
            <PrivateRoute path='/upload-data/registry-new' component={RegistryScreenCopy} exact></PrivateRoute>
            {/*<PrivateRoute path='/history' component={HistoryScreen} exact></PrivateRoute> 
            <Route component={NotFoundScreen} />*/}
          </Switch>
        </MainLayout>
      </BrowserRouter>
    </MuiThemeProvider>
  );


}

export default App;
