import React, { useEffect, useState } from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from '@material-ui/core/Button';
import Badge from '@material-ui/core/Badge';
import Container from '@material-ui/core/Container';

import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { MainListItems, SecondaryListItems } from '../components/listItems';
import Copyright from '../components/Copyright';
import { useDispatch, useSelector } from 'react-redux';

import MainLayoutStyle from '../assets/jss/glifos/mainLayoutStyle';
import { signout } from '../actions/userActions';

import logo from "../assets/images/logo.svg";
import axios from 'axios';
import ApiURLs from '../config/ApiURLs';


const useStyles = makeStyles(MainLayoutStyle);

export default function MainLayout(props) {
    const userSignin = useSelector((state) => state.userSignin);
    const { userInfo } = userSignin;
    const classes = useStyles();
    const [open, setOpen] = React.useState(true);
    const handleDrawerOpen = () => {
        setOpen(true);
    };
    const handleDrawerClose = () => {
        setOpen(false);
    };
    const dispatch = useDispatch();
    const handleLogout = (e) => {
        e.preventDefault();
        dispatch(signout());
    };

    const [isGeneralLoginSite, setIsGeneralLoginSite] = useState(false);
    const currentUrl = window.location.href;

    useEffect(() => {
        if (currentUrl.includes(`https://${window.location.host}/login`)) {
            if (userInfo) {
                axios.post(`${ApiURLs.baseURL}api/session`, {}, {
                    headers: { Authorization: `Bearer ${userInfo.access_token}`, },
                }).then(function (response) {
                    if (!response.data.error) {
                        const session_data = JSON.stringify(response.data);
                        localStorage.setItem("userInfo", session_data);

                        var expirationDate = new Date();
                        expirationDate.setTime(expirationDate.getTime() + (0.5 * 60 * 60 * 1000)); // Convert hours to milliseconds

                        var expires = "expires=" + expirationDate.toUTCString();
                        document.cookie = ApiURLs.cookie + "=" + userInfo.access_token + ';' + expires + ';Path=/';
                        window.location.replace(`https://${window.location.host}/bienvenido`);
                    } else {
                        dispatch(signout());
                        window.location.replace(`https://${window.location.host}/login`);
                    }
                }).catch(function (err) {
                    dispatch(signout());
                    window.location.replace(`https://${window.location.host}/login`);
                }).finally(function () { });
            }
            setIsGeneralLoginSite(true);
        }
    }, [userInfo]);

    if (props.userInfo != null && !isGeneralLoginSite) {
        return (
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
                    <Toolbar className={classes.toolbar}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={handleDrawerOpen}
                            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                            Flujograma de la justicia criminal
                        </Typography>
                        {/* <IconButton color="inherit">
                            <Badge badgeContent={0} color="secondary">
                                <NotificationsIcon />
                            </Badge>
                        </IconButton> */}
                        {userInfo ?
                            <IconButton color="inherit" onClick={(e) => handleLogout(e)}>
                                <ExitToAppIcon />
                            </IconButton> : <Button variant="contained" color="primary">Sign in</Button>
                        }
                    </Toolbar>
                </AppBar>
                <Drawer
                    variant="permanent"
                    classes={{
                        paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
                    }}
                    open={open}
                >
                    <div className={classes.toolbarIcon}>
                        <div className={classes.headerLogoContainer}>
                            <img src={logo} className={classes.headerLogo} />
                        </div>
                        <IconButton onClick={handleDrawerClose}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </div>
                    <Divider />
                    <List>{<MainListItems userInfo={userInfo} />}</List>
                    {/* <Divider />
                    <List>{<SecondaryListItems userInfo={userInfo} ></SecondaryListItems>}</List> */}
                    {open ? <Copyright /> : null}

                </Drawer>
                <main className={classes.content}>
                    <div className={classes.appBarSpacer} />
                    <Container maxWidth="lg" className={classes.container}>
                        {props.children}

                    </Container>
                </main>
            </div>
        );
    } else {
        return <Container maxWidth="lg" className={classes.container}>{props.children}</Container>;
    }
}
