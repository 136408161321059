import React from 'react'
import { useSelector } from 'react-redux';
import { Redirect, Route, useLocation } from 'react-router-dom'

const PrivateRoute = ({ component: Component, ...rest }) => {

  const userSignin = useSelector((state) => state.userSignin);
  const { userInfo } = userSignin;

  const [isGeneralLoginSite, setIsGeneralLoginSite] = React.useState(false);
  const location = useLocation();
  const pathnameParts = location.pathname.split('/'); // Split the pathname into parts

  React.useEffect(() => {
    if (pathnameParts.length !== 1) {
      if (pathnameParts[1] === 'login') {
        setIsGeneralLoginSite(true);
      }
    }
  })

  return (
    <Route
      {...rest}
      render={props =>
        userInfo ? (
          <Component {...props} />
        ) : (
          <Redirect to={{ pathname: isGeneralLoginSite ? '/login' : '/upload-data/login', state: { from: props.location } }} />
        )
      }
    />
  )
}

export default PrivateRoute;