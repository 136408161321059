import ApiURLs from '../config/ApiURLs.js';
import {
	USER_SIGNIN_FAIL,
	USER_SIGNIN_REQUEST,
	USER_SIGNIN_SUCCESS,
	USER_SIGNOUT,
} from '../constants/userConstants.js';
/*import Axios from 'axios';*/
import Axios from '../services/Api.js';

export const signin = (username, password, remember) => async (dispatch) => {
	console.log(Axios);

	dispatch({ type: USER_SIGNIN_REQUEST, payload: { username, password } });
	try {
		//const {data} = await Axios.post('/glifos/auth',{username,password,remember});
		const { data } = await Axios.UserLogin({
			username,
			password,
			remember,
		});
		if (data?.access_token === null || data?.access_token === undefined) {
			dispatch({
				type: USER_SIGNIN_FAIL,
				payload:
					(username === '' || password === '') ? 'Ingrese usuario o contraseña' : 'Usuario o contraseña erroneo',
			});
		} else {
			dispatch({ type: USER_SIGNIN_SUCCESS, payload: data });
			localStorage.setItem('userInfo', JSON.stringify(data));

			var expirationDate = new Date();
			expirationDate.setTime(expirationDate.getTime() + (0.5 * 60 * 60 * 1000)); // Convert hours to milliseconds

			var expires = "expires=" + expirationDate.toUTCString();
			document.cookie = ApiURLs.cookie + "=" + data.access_token + ';' + expires + ';Path=/';
		}
	} catch (error) {
		dispatch({
			type: USER_SIGNIN_FAIL,
			payload:
				error.response && error.response.data.message
					? error.response.data.message
					: error.message,
		});
	}
};
export const signout = () => async (dispatch) => {
	console.log('signing out...');
	localStorage.removeItem('userInfo');
	document.cookie = ApiURLs.cookies + "=" + '=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT;';
	dispatch({ type: USER_SIGNOUT });
};
