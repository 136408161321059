import axios from 'axios';
import ApiURLs from '../config/ApiURLs.js';

class Service {
	constructor() {
		let headers = {};

		let userLocal = localStorage.getItem('userInfo')
			? JSON.parse(localStorage.getItem('userInfo'))
			: null;

		if (
			userLocal &&
			userLocal.access_token /* && userLocal.session.jwt */
		) {
			headers.Authorization = `Bearer ${userLocal.access_token}`;
		}
		let service = axios.create({
			baseURL: ApiURLs.baseURL,
			headers: headers,
		});

		Object.keys(ApiURLs).forEach((element) => {
			if ('baseURL' !== element) {
				Service.prototype[element] = function (payload) {
					let validPayload = true;
					if (undefined !== ApiURLs[element].requires)
						// Parameters Required
						ApiURLs[element].requires.forEach((elementPayload) => {
							validPayload =
								validPayload &&
								payload.hasOwnProperty(elementPayload);
						});
					if (!validPayload)
						return Promise.reject({
							message: 'Not a valid request',
							error: true,
						});
					//console.log('this.service', this.service);
					return this[ApiURLs[element].method](
						ApiURLs[element].href,
						payload
					);
				};
			}
		});

		this.headers = headers;
		this.service = service;
		this.service.interceptors.response.use(
			this.handleSuccess,
			this.handleError
		);
	}

	checkAuth() {
		console.log('check auth...');
		if (!this.headers.Authorization) {
			console.log('no hay Authorization');
			if (
				localStorage.userInfo &&
				localStorage.access_token /* &&
				localStorage.session.jwt */
			) {
				console.log('seteamos authorization');
				this.headers.Authorization = `Bearer ${localStorage.userInfo.access_token}`;
			}
		}
	}

	handleSuccess(response) {
		return response;
	}

	handleError = (error) => {
		console.log(error);
		switch (error.response.status) {
			case 401:
				localStorage.removeItem('userInfo');
				// window.location.href = '/upload-data';
				break;
			/*case 404:
        this.redirectTo(document, '/404')
        break;*/
			/* default:
				this.redirectTo(document, '/500');
				break; */
		}
		return Promise.reject(error);
	};

	redirectTo = (document, path) => {
		document.location = path;
	};

	get(path, callback) {
		return this.service
			.get(path + 'f13b76e209ca8c23f50f599a5bf93002')
			.then((response) => callback(response.status, response.data));
	}

	patch(path, payload, callback) {
		return this.service.request({
			method: 'PATCH',
			url: path,
			responseType: 'json',
			data: payload,
		}); //.then((response) => callback(response.status, response.data));
	}

	post(path, payload, callback) {
		return this.service.request({
			method: 'POST',
			url: path,
			responseType: 'json',
			data: payload,
		});
	}
}

export default new Service();
