const url = window.location.host;
let to = '';
let cookie = '';
let redirect = '';
if (url === 'beta.justiciacriminal.gt') {
	to = 'api-beta.justiciacriminal.gt';
	redirect = 'https://beta.justiciacriminal.gt';
	cookie = 'DAJCBETA';
} else if (url === 'dev.justiciacriminal.gt') {
	to = 'test.justiciacriminal.gt';
	redirect = 'https://dev.justiciacriminal.gt';
	cookie = 'DAJCDEV';
} else if (url === 'justiciacriminal.gt') {
	to = 'app.justiciacriminal.gt';
	redirect = 'https://justiciacriminal.gt';
	cookie = 'DAJC';
} else {
	to = 'flujograma.app.local';
	redirect = 'http://localhost:3000';
	cookie = 'DAJCLOCAL';
}

const ApiURLs = {
	publicFilesURL: `https://${to}/v1`,
	//publicFilesURL: 'https://app.justiciacriminal.gt/v1',
	//baseURL: 'https://app.justiciacriminal.gt/v1/',
	baseURL: `https://${to}/v1/`,
	cookie: cookie,
	redirect: redirect,
	UserLogin: {
		href: '/auth/authenticate',
		method: 'post',
		requires: ['username', 'password', 'remember'],
	},
	ReadUserBatch: {
		href: '/glifos/api/checkin/batch',
		method: 'post',
	},
	UploadFile: {
		href: '/file/upload',
		method: 'post',
	},
	dowloadFile: {
		href: '/glifos/file/download/',
		method: 'get',
	},
	getFileMetadata: {
		href: '/lot/lot-data',
		method: 'get'
	}
};

export default ApiURLs;
