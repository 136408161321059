import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import Container from '@material-ui/core/Container';

import { makeStyles } from '@material-ui/core/styles';
import styles from '../assets/jss/glifos/mainLayoutStyle';
import logo from '../assets/images/logo.svg';
import logoBeta from '../assets/images/logo.beta.svg';

import Copyright from '../components/Copyright';
import { useDispatch, useSelector } from 'react-redux';

import { signin } from '../actions/userActions.js';
import MessageBox from '../components/MessageBox';
import LoadingBox from '../components/LoadingBox';
import { useLocation } from 'react-router-dom';
import ApiURLs from '../config/ApiURLs';
import axios from 'axios';

const useStyles = makeStyles(styles);

function LoginScreen(props) {
	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [remember, setRemember] = useState(false);
	const userSignin = useSelector((state) => state.userSignin);
	const { userInfo, loading, error } = userSignin;
	const classes = useStyles();
	const [emailError, setEmailError] = useState(false);
	const [passwordError, setPasswordError] = useState(false);
	const dispatch = useDispatch();
	const submitHandler = (e) => {
		e.preventDefault();
		setEmailError('' === email.trim());
		setPasswordError('' === password.trim());
		dispatch(signin(email, password, remember));
		//TODO: SIGN IN ACTION
	};

	const [isGeneralLoginSite, setIsGeneralLoginSite] = useState(false);
	const location = useLocation();
	const pathnameParts = location.pathname.split('/'); // Split the pathname into parts

	useEffect(() => {
		if (pathnameParts.length !== 1) {
			if (pathnameParts[1] === 'login') {
				setIsGeneralLoginSite(true);
			}
		}
	}, [userInfo]);

	return userInfo ? <></> : (
		<Container component='main' maxWidth='xs'>
			<CssBaseline />
			<div className={classes.paper}>
				<div className={classes.mainLogo}>
					<img src={isGeneralLoginSite ? logoBeta : logo} alt='Logo' className={classes.fitImage} />
				</div>
				<div className={classes.mainLogo}>
					<Typography component='h1' variant='h5'>
						Ingreso
					</Typography>
				</div>
				{loading && <LoadingBox></LoadingBox>}

				<form
					className={classes.form}
					noValidate
					onSubmit={submitHandler}>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						id='email'
						label='Email'
						name='email'
						autoComplete='email'
						error={emailError}
						autoFocus
						onChange={(e) => setEmail(e.target.value)}
					/>
					<TextField
						variant='outlined'
						margin='normal'
						required
						fullWidth
						name='password'
						label='Password'
						type='password'
						id='password'
						error={passwordError}
						autoComplete='current-password'
						onChange={(e) => setPassword(e.target.value)}
					/>
					<FormControlLabel
						control={
							<Checkbox
								value='remember'
								color='primary'
								onChange={(e) => setRemember(e.target.value)}
							/>
						}
						label='Recordarme en este navegador'
					/>
					<Button
						type='submit'
						fullWidth
						variant='contained'
						color='primary'
						className={classes.submit}>
						Sign In
					</Button>
					<div style={{ marginTop: '15px' }}>
						<Grid container>
							<Grid item xs>
								{/* TODO: Colocar dominio final */}
								<Link
									href='https://api-beta.justiciacriminal.gt/app-user/forgot'
									variant='body2'>
									¿Olvidó su contraseña?
								</Link>
							</Grid>
						</Grid>
					</div>

					{(emailError || passwordError) && (
						<MessageBox variant='danger' severity='error'>
							<Typography>
								{'Por favor ingrese sus credenciales'}
							</Typography>
						</MessageBox>
					)}
					{error && !(emailError || passwordError) && (
						<MessageBox variant='danger' severity='error'>
							{error}
						</MessageBox>
					)}
				</form>
			</div>
			<Box mt={8}>
				<Copyright />
			</Box>
		</Container>);
}

export default LoginScreen;
