import { UPLOAD_REQUEST, UPLOAD_REQUEST_SUCCESS, UPLOAD_REQUEST_ERROR, DELETE_REQUEST, DELETE_REQUEST_ERROR, DELETE_REQUEST_SUCCESS } from "../constants/documentConstants";
import Swal from 'sweetalert2';

import Axios from '../services/Api.js';

export const uploadFile = (file) => async (dispatch) => {
    dispatch({ type: UPLOAD_REQUEST });

    const fileA = file.getAll('file')[0];
    try {
        const formData = new FormData();
        formData.append('File[digitalFile]',fileA)
        console.log(fileA);

        const { data } = await Axios.UploadFile(formData);
        const result = {
            id: data.id,
            name: fileA.name,
            size: fileA.size
        }
        dispatch({ type: UPLOAD_REQUEST_SUCCESS, payload: result });

        Swal.fire(
            'Archivo',
            'Documento cargado correctamente',
            'success'
        )
    } catch (error) {
        console.log(error);
        dispatch({ type: UPLOAD_REQUEST_ERROR });
        Swal.fire({
            icon: 'error',
            title: 'Hubo un error',
            text: 'Error inesperado al subir archivo'
        });
    }
}

export const deleteFile = (id) => async (dispatch) => {
    dispatch({ type: DELETE_REQUEST });

    try {
        //const { data } = await Axios.dowloadFile(id);
        //console.log(data);

        dispatch({ type: DELETE_REQUEST_SUCCESS, payload: id });

        Swal.fire(
            'Archivo',
            'Archivo eliminado correctamente',
            'success'
        )
    } catch (error) {
        console.log(error);
        dispatch({ type: DELETE_REQUEST_ERROR });
        Swal.fire({
            icon: 'error',
            title: 'Hubo un error',
            text: 'Error inesperado al eliminar el archivo'
        });
    }
}

